import React from "react";
import CookieConsent from "react-cookie-consent";
import useWindowSize from "../../helpers/UseWindowSize";

import { Wrapper, Content, LinkItem, ButtonClose } from "./index.styled";

const CookiesBanner = () => {
  const ref = React.useRef()
  const { width } = useWindowSize()

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      ref?.current?.classList?.add('show')
    }, 1000)
    return () => clearTimeout(timeout)
  }, [])

  const onClose = () => {
    ref?.current?.classList?.remove('show')
  }

  return (
    <Wrapper ref={ref}>
      <CookieConsent
        location='none'
        buttonText='Allow'
        disableStyles
        containerClasses='cookies-banner'
        buttonClasses='cookies-banner-accept-btn'
        disableButtonStyles
        buttonWrapperClasses='cookies-banner-btns'
        contentClasses='cookies-banner-content'
        expires={150}
        // debug={true}
      >
        <Content>
          {width >= 768
            ? (
              <>
                This website uses <LinkItem to='/legal'>cookies</LinkItem> to enhance your experience.
              </>
              )
            : (
              <>
                This website uses <LinkItem to='/legal'>cookies</LinkItem>.
              </>
              )}
        </Content>
        <ButtonClose onClick={onClose} />
      </CookieConsent>
    </Wrapper>
  )
}
export default CookiesBanner
