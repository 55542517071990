import React from "react";
import styled from "styled-components";
import {mediaMax} from "../../helpers/MediaQueries";
import { Link } from "gatsby";
import Close from "../../assets/images/button_close.svg";

export const Wrapper = styled.div`
  .cookies-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
    padding: 19px 100px;
    font-size: 20px;
    line-height: 2;
    letter-spacing: 0.4px;
    border-top: 1px solid #000000;
    background: #f1efeb;
    opacity: 0;
    visibility: hidden;
    transform: translateY(150%);
    transition: opacity 1s, transform 1s;
    z-index: 1000;
  }
  &.show {
    .cookies-banner {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }
  .cookies-banner-accept-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    padding: 0 16px;
    text-decoration: none !important;
    font-size: 20px;
    color: #ffffff;
    line-height: 1.2;
    font-weight: 300;
    letter-spacing: 2px;
    text-transform: uppercase;
    border: 0;
    background: #C9C3B8;
    transition: color 0.25s, background 0.25s;
    cursor: pointer;
    &:hover {
      background: rgb(239, 238, 234);
      background:white;
      color: rgb(41, 41, 41);
    }
  }
  ${mediaMax.tablet`
    .cookies-banner {
      justify-content: flex-start;
      gap: 20px;
      padding: 14px 40px 14px 20px;
      font-size: 14px;
    }
    .cookies-banner-accept-btn {
      height: 32px;
      font-size: 14px;
    }

  `}
`

export const Content = styled.div``

export const LinkItem = styled((props) => <Link {...props} />)`
  text-decoration: none;
  color: #000000;
  &:hover {
    text-decoration: underline;
  }
`

export const ButtonClose = styled.div`
background: white;
  position: absolute;
  top: 50%;
  right: 60px;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
  background: url(${Close}) center center no-repeat;
  background-size: 20px auto;
  transition: transform 0.25s;
  cursor: pointer;
  &:hover {
      transform: translateY(-50%) scale(1.1);
  }
  ${mediaMax.tablet`
    right: 20px;
  `}
`
